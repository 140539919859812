.str-chat-channel-list {
  height: fit-content;
}

.str-chat__channel-list {
  max-height: calc(50% - 10px);
  overflow-y: auto;
}

.team-channel-list {
    position: relative;

  .str-chat__avatar-fallback {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary-color)
  }

  .team-channel-list__message {
    padding: 0 16px;
  }

  .team-channel-list__message.loading {
    height: 115px;
  }

  .team-channel-list__header {
    display: flex;
    position: sticky;
    background: #7F0B3E;
    z-index: 50;
    top: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    .team-channel-list__header__title {
      height: 16px;
      margin-bottom: 10px;
    }

    button {
      display: flex;
      align-items: center;
      padding-block: 4px;
    }
  }
}

.channel-list__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .channel-list__empty-state__text {
        font-size: 0.8rem;
        font-weight: 400;
        color: #EBE9F4;
    }
}