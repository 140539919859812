@use '../utils';

.channel-preview {
  display: flex;
  align-items: center;
  width: 90%;
  height: 37px;
  padding: 0;

  &.selected {
    display: flex;
    align-items: center;
    height: 37px;
  }
}

.channel-preview__item {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 220px;
  height: 100%;
  width: 100%;
  padding-left: 26px;

  p {
    @include ellipsis-text;
  }

  &.single .str-chat__avatar {
    margin-right: 12px;
  }

  &.multi .str-chat__avatar {
    margin-right: 0;
  }

  &.multi span:first-child {
    position: relative;
    z-index: 2;
    bottom: 6px;
  }

  &.multi div:nth-child(3) {
    position: relative;
    right: 10px;
    z-index: 1;
    margin-bottom: 0;
  }

  &.unread {
    font-weight: bold;
    color: white;
  }

  &__unread {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    text-align: center;
    background: var(--static-background-color);
  }
}
