.auth__form {
    width: 320px;

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/bubbles.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: top center;
        background-color: #EFEFEF;
    }
}
.auth__input {
    width: 100%;
    height: 3rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
    border: 1px solid #b2b1b5;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #15020A;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    &::placeholder {
        color: #b2b1b5
    }
    &:focus {
        outline: none;
        border-color: #b2b1b5;
        box-shadow: 0 0 0 1px #b2b1b5;
    }
}
.auth__error {
    margin-bottom: 1rem;
    margin-top: -0.25rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #ff0000;
}
.auth__button {
    cursor: pointer;
    width: 100%;
    height: 3rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
    border: 1px solid #AA0F52;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFF;
    background-color: #AA0F52;
    transition: all 0.2s ease-in-out;
    &:focus {
        outline: 1px solid #AA0F52;
        border-color: #7F0B3E;
        background-color: #7F0B3E;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
.auth__header {
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: 2rem;
    color: #AA0F52;
}