@layer default-chat-sdk {
  @import '~stream-chat-react/dist/scss/v2/index.scss';
}

@import "global_theme_elements";
@import "global_theme_variables";
@import "layout";

@import "AdminPanel";
@import "Auth";
@import "Attachment";
@import "Channel";
@import "ChannelList";
@import "ChannelSearch";
@import "Message";
@import "MessageActions";
@import "MessageInput";
@import "MessageList";
@import "MessageReactions";
@import "PinnedMessageList";
@import "Sidebar";
@import "Thread";
@import "TypingIndicator";
@import "UserList";
@import "WorkspaceHeader";

*::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #b2b1b5;
  border-radius: 2px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7F0B3E;
    font-size: 2rem;
    font-weight: 600;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
