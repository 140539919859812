.sidebar {
  display: flex;
  height: 100%;

  .sidebar__app-logo {
    width: 100%;
    text-align: center;
    img {
        border-radius: 8px;
        border: 1px solid var(--border-color);
        width: auto;
        max-width: 100%;
        padding: 8px;
        max-height: 50px;
        object-fit: contain;
        background-color: #fff;
    }
  }

  .sidebar__company-selection-bar {
    width: 80px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .sidebar__company-badge {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
    }
  }

  .sidebar__signout-button {
    width: 80px;
    padding: 14px;
    cursor: pointer;
    background-color: transparent;
    font-size: .6rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    border: none;
  }

  .channel-list-bar {
    display: flex;
    flex-direction: column;
    width: 240px;
    padding: 16px 0;
    max-height: 100%;

    .channel-list-bar__header {
      padding-inline: 16px;
      margin-bottom: 12px;
    }
  }
}

.moderator-button-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: auto;
}


.moderator-button-image {
  max-width: 100%;
}

.moderator-button {
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.moderator-button:hover {
  background: rgba(0, 0, 0, 0.2)
}